"use strict";

/**
 * (PC版)ヘッダの検索ボタン押下時のアクション
 *
 */
 const onClickHeaderFormPc = () => {
  const trigger = document.getElementById("jobSearchSubmit");
  trigger.addEventListener("click", function(event) {
    event.preventDefault()

    const category = document.getElementById("selectCategory").value;
    const hiddenCategory = document.getElementById("hiddenCategory");
    hiddenCategory.value = category;
     
    setSearchParams();
    const form = document.getElementById("jobSearchForm");
    form.submit();
  });
};


/**
 * (PC版)サイドバーからカテゴリを選択する
 *
 */
const onSelectCategoryPc = () => {
  const triggers = document.querySelectorAll(".categories_pc");
  triggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      const categoryId = trigger.dataset.categoryId;
      const hiddenCategory = document.getElementById("hiddenCategory");
      hiddenCategory.value = categoryId;

      setSearchParams();
      const form = document.getElementById("jobSearchForm");
      form.submit();
    });
  });
};

/**
 * (SP版)サイドバーからカテゴリを選択する
 *
 */
 const onSelectCategorySp = () => {
  const triggers = document.querySelectorAll(".categories_sp");
  triggers.forEach((trigger) => {
    trigger.addEventListener("change", () => {
      const categoryId = trigger.value;
      
      const hiddenCategory = document.getElementById("hiddenCategory");
      hiddenCategory.value = categoryId;

      // setSearchParams();
      const form = document.getElementById("jobSearchForm");
      form.submit();
    });
  });
};

const setSearchParams = () => {
  const hiddenSort = document.getElementById("hiddenSort");
  const sort = document.getElementById("sort");
  hiddenSort.value = sort != null ? sort.value : 'new';
}


document.addEventListener("DOMContentLoaded", () => {
  onClickHeaderFormPc();
  onSelectCategoryPc();
  onSelectCategorySp();
});
